import ApiService from './api.service'

const GameService = {
  index () {
    return ApiService.get('api/scorekeeper/games')
  },
  live () {
    return ApiService.get('api/v1/game/live')
  },
  goLive (data) {
    return ApiService.postWithoutLoading('api/v1/game/live', data)
  },
  backToLive (data) {
    return ApiService.put('api/v1/game/back-to-live', data)
  },
  preGame () {
    return ApiService.get('api/v1/game/pre_game')
  },
  status () {
    return ApiService.get('api/v1/game/status')
  },
  enterTheGame (code) {
    return ApiService.get('api/v1/game/status', {
      headers: {
        code: code
      }
    })
  },
  embedVideo (data) {
    return ApiService.postWithoutLoading('api/v1/game/live/embedVideo', data)
  },
  addPlayerDebt (data) {
    return ApiService.postWithoutLoading('api/v1/game/live/debts/player', data)
  },
  addTeamDebt (data) {
    return ApiService.postWithoutLoading('api/v1/game/live/debts/team', data)
  },
  payDebt (debtUuid) {
    return ApiService.postWithoutLoading(`api/v1/game/live/debts/${debtUuid}/pay-debt`)
  },
  gameLog (data) {
    return ApiService.postWithoutLoading('api/v1/game/live/gamelog', data)
  },
  deleteLog (uuid) {
    return ApiService.deleteWithoutLoading('api/v1/game/live/gamelog/' + uuid)
  },
  deleteLogWithoutAsking (uuid) {
    return ApiService.deleteWithoutAsking('api/v1/game/live/gamelog/' + uuid)
  },
  setPeriod (data) {
    return ApiService.put('api/v1/game/live/period', data)
  },
  endGame () {
    return ApiService.postWithoutLoading('api/v1/game/live/end')
  },
  archive (data) {
    return ApiService.postWithoutLoading('api/v1/game/live/archive', data)
  },
  getArchive () {
    return ApiService.get('api/v1/game/live/getarchive')
  },
  getPlayersOfTheGame (homeTeamScoreTotal, awayTeamScoreTotal) {
    return ApiService.get(`api/v1/game/live/players-of-the-game?homeTeamScoreTotal=${homeTeamScoreTotal}&awayTeamScoreTotal=${awayTeamScoreTotal}`)
  },
  stopwatch () {
    return ApiService.get('api/v1/game/stopwatch')
  }
}

export default GameService
