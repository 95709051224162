<template>
  <div class="timer-toggle" :class="{'time-editing': isEditMode, 'active-stopwatch': isActiveCurrentStopwatch}">
    <div v-if="!isLoaded">
      <button class="btn btn-info px-2" style="min-width: 100px"><i class="fa fa-cog fa-spin"></i></button>
    </div>
    <div
      v-if="isLoaded"
    >
      <div v-if="isActiveCurrentStopwatch" class="edit-buttons-wrapper up d-flex justify-content-between">
        <button
          class="btn btn-info px-2"
          @click="increaseTime(60000)"
        >
          <i class="fa fa-chevron-up"/>
        </button>
        <button
          class="btn btn-info px-2"
          @click="increaseTime(1000)"
        >
          <i class="fa fa-chevron-up"/>
        </button>
      </div>
      <div v-if="!isActiveCurrentStopwatch" class="edit-buttons-wrapper w-100 up d-flex justify-content-between">
        <button
          class="btn btn-warning px-1 font-s flex-grow-1 eject-timer"
          @click="ejectTimer()"
        >
          {{$t('ejectMainStopwatch')}}
        </button>
      </div>
      <div class="flex flex-row btn-group">
        <button
          @click.right.prevent="toggleEditMode"
          :class="{ 'btn-danger': !isRunning && isActiveCurrentStopwatch, 'btn-success': isRunning && isActiveCurrentStopwatch, 'btn-info': !isActiveCurrentStopwatch }"
          class="btn accept-log"
          type="button"
          @click="toggleTime"
        >
          {{ currentTimeToShow }}
        </button>
        <button class="btn px-2" :class="{'btn-success': isEditMode, 'btn-info': !isEditMode}" @click="toggleEditMode()"><i class="fa fa-pencil"></i></button>
      </div>
      <div v-if="isActiveCurrentStopwatch" class="edit-buttons-wrapper down d-flex justify-content-between">
        <button
          @click="decreaseTime(60000)"
          class="btn btn-info px-2"
        >
          <i class="fa fa-chevron-down"/>
        </button>
        <button
          @click="decreaseTime(1000)"
          class="btn btn-info px-2"
        >
          <i class="fa fa-chevron-down"/>
        </button>
      </div>
    </div>
  </div>

</template>
<script>
import { useStopwatch } from '@/use/useStopwatch'

export default {
  name: 'LeStopwatch',
  setup () {
    const {
      updateTime,
      config,
      gameUuid,
      currentClient,
      updateStopwatchTimeEverySecond,
      decreaseShotClockTime,
      increaseShotClockTime,
      currentShotClockToShow,
      currentTimeToShow,
      currentTime,
      resetShotClock,
      toggleEditMode,
      increaseTime,
      decreaseTime,
      startTimer,
      stopTimer,
      initStopwatch,
      destroyStopwatch,
      isEditMode,
      isRunning,
      playBuzzer,
      isLoaded,
      activeStopwatch,
      toggleTime,
      ejectTimer,
      isActiveCurrentStopwatch
    } = useStopwatch('smallStopwatch')

    return {
      updateTime,
      config,
      gameUuid,
      currentClient,
      updateStopwatchTimeEverySecond,
      decreaseShotClockTime,
      increaseShotClockTime,
      currentShotClockToShow,
      currentTimeToShow,
      resetShotClock,
      toggleEditMode,
      increaseTime,
      decreaseTime,
      startTimer,
      stopTimer,
      initStopwatch,
      destroyStopwatch,
      isEditMode,
      isRunning,
      playBuzzer,
      currentTime,
      isLoaded,
      activeStopwatch,
      toggleTime,
      ejectTimer,
      isActiveCurrentStopwatch
    }
  },
  mounted () {
    this.initStopwatch()

    window.addEventListener('keydown', this.keyboardListener)
  },
  beforeDestroy () {
    this.destroyStopwatch()

    window.removeEventListener('keydown', this.keyboardListener)
  },
  methods: {
    keyboardListener (event) {
      if (['Space', 'Numpad0', 'Digit0'].includes(event.code)) {
        event.preventDefault()
        this.toggleTime()
      }
    }
  }
}
</script>
<style>
.timer-toggle {
  position: fixed;
  right: 15px;
  bottom: 15px;
  transition: 0.15s;
  z-index: 9999999;
}

.timer-toggle.time-editing.active-stopwatch {
  bottom: 15px;
}

.timer-toggle.time-editing {
  bottom: 50px;
}

.edit-buttons-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  visibility: hidden;
  opacity: 0;
}

.timer-toggle.time-editing .edit-buttons-wrapper {
  visibility: visible;
  opacity: 1;
}

.edit-buttons-wrapper.up {
  top: -8px;
  transform: translateY(-100%);
}

.edit-buttons-wrapper.down {
  bottom: -8px;
  transform: translateY(100%);
}

.edit-buttons-wrapper button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-buttons-wrapper .eject-timer {
  font-size: 11px;
  word-wrap: anywhere;
  text-wrap: balance;
}

.blob {
  transform: scale(1);
  animation: pulse 1.2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.50);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.50);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.7);
  }
}
</style>
