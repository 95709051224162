<template>
  <header id="page-header">
    <!-- Header Content -->
    <div class="content-header">
      <!-- Left Section -->
      <div class="d-flex align-items-center">
        <!-- Toggle Sidebar -->
        <!-- Layout API, functionality initialized in Template._uiApiLayout()-->
        <button type="button"
                v-if="$route.name === 'live'"
                @click="$router.push({name: 'preGame'})"
                class="btn btn-sm mr-2">
          <i class="fa fa-fw fa-arrow-left"></i>
        </button>
        <button type="button"
                v-if="$route.name === 'archive'"
                @click="backToLive()"
                class="btn btn-sm mr-2">
          <i class="fa fa-fw fa-arrow-left"></i>
        </button>
        <button href="#" v-if="['preGame', 'archive'].includes($route.name)"
                class="dropdown-item d-flex align-items-center justify-content-between"
                @click.prevent="logoutOfGame()">
          <i class="si si-logout"></i>
        </button>
        <button @click="goToStopwatch()" class="btn btn-sm btn-info" v-if="['preGame', 'live'].includes($route.name)"><i class="fa fa-clock-o"></i></button>
        <button @click="goBackToScorekeeper()" class="btn btn-sm btn-info" v-if="$route.name === 'stopwatch'"><i class="fa fa-calculator"></i></button>
      </div>
      <div v-if="isMobile && $route.name === 'live'" class="d-flex align-items-center">
        <h4 class="m-0 d-flex">{{ score }}</h4>
      </div>
      <!-- END Left Section -->

      <!-- Right Section -->
      <div class="d-flex align-items-center">
        <!--<select class="form-control form-control-alt" v-model="language" id="example-select" name="example-select">-->
        <!--<option v-for="lng in availableLanguages" :key="lng">{{lng}}</option>-->
        <!--</select>-->
        <ntm-dropdown
          class="ml-2"
          v-if="$route.name === 'live' && !isMobile"
          v-model="show"
          :right="true"
          :hover="false"
          :interactive="true"
        >
          <button class="btn btn-sm">
            <i class="fa fa-cog"></i>
          </button>
          <div slot="dropdown">
            <div class="dropdown-settings-item">
              <div class="custom-control custom-switch py-1 px-2 mb-2 actions-position d-flex justify-content-between">
                <span>{{ $t('live.actionsPosition') }}</span>
                <div>
                  <input type="radio" class="custom-control-input" :checked="actionsPositionValue === 'left'"
                         name="actionsPosition" id="actionsLeft" value="1" v-model="actionsPositionValue">
                  <label class="custom-control-label" for="actionsLeft" role="button"><i class="fa fa-arrow-left"
                                                                                         aria-hidden="true"></i></label>
                </div>
                <div>
                  <input type="radio" class="custom-control-input" :checked="actionsPositionValue === 'center'"
                         name="actionsPosition" id="actionsCenter" value="2" v-model="actionsPositionValue">
                  <label class="custom-control-label" for="actionsCenter" role="button"><i
                    class="fa fa-minus fa-rotate-90" aria-hidden="true"></i></label>
                </div>
                <div>
                  <input type="radio" class="custom-control-input" :checked="actionsPositionValue === 'right'"
                         name="actionsPosition" id="actionsright" value="3" v-model="actionsPositionValue">
                  <label class="custom-control-label" for="actionsright" role="button"><i class="fa fa-arrow-right"
                                                                                          aria-hidden="true"></i></label>
                </div>
              </div>
              <div class="custom-control custom-switch py-1 px-2 mb-1 rounded">
                <input type="checkbox" class="custom-control-input" :checked="autoUnselectValue" id="autoUnselect"
                       @change="autoUnselect">
                <label class="custom-control-label" for="autoUnselect" role="button">{{
                    $t('live.autoUnselect')
                  }}</label>
              </div>
              <div  class="custom-control custom-switch py-1 px-2 mb-1 rounded">
                <input type="checkbox" class="custom-control-input" :checked="invertTableValue" id="invertedTable"
                       @change="invertTable">
                <label class="custom-control-label" for="invertedTable" role="button">{{
                    $t('live.invertTable')
                  }}</label>
              </div>
              <div class="custom-control custom-switch py-1 px-2 rounded">
                <input type="checkbox" class="custom-control-input" :checked="reverseTeamsValue" id="customSwitch1"
                       @change="reverseTeams">
                <label class="custom-control-label" for="customSwitch1" role="button">{{
                    $t('live.switchTeams')
                  }}</label>
              </div>
            </div>
          </div>
        </ntm-dropdown>
        <button v-if="isMobile" @click="showMobileSettings()" class="btn btn-sm ml-2 mobile-settings-trigger">
          <i class="fa fa-cog"></i>
        </button>
        <div v-if="['preGame', 'archive'].includes($route.name)" class="dropdown d-inline-block ml-2">
          <button type="button"
                  class="btn btn-sm btn-dual"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
            <i style="font-size: 20px;" class="fa fa-language"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm"
               aria-labelledby="page-header-notifications-dropdown">
            <div class="p-2 bg-primary text-center">
              <h5 class="dropdown-header text-uppercase text-white">{{ $tc('language.name', 2) }}</h5>
            </div>
            <ul class="nav-items mb-0">
              <li v-for="lng in availableLanguages" :class="({'bg-info-light': currentLanguage === lng.id})" :key="lng.id">
                <a class="text-dark media py-2" href="#" @click.prevent="setLanguage(lng.id)">
                  <div class="mr-2 ml-3">
                    <i style="font-size: 16px" :class="'flag-icon flag-icon-' + lng.img"></i>
                  </div>
                  <div class="media-body pr-2">
                    <div class="font-w600">{{ $t(lng.name) }}</div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- END User Dropdown -->
        <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
        <button v-if="$route.name === 'live'" type="button"
                id="sidebarTriggerButton"
                class="btn btn-sm btn-dual ml-2"
                @click="toggleSidebar">
          <i class="fa fa-history"></i>
        </button>
        <!-- END Toggle Side Overlay -->
      </div>
      <!-- END Right Section -->
    </div>
    <!-- END Header Content -->

    <!-- Header Search -->
    <div id="page-header-search" class="overlay-header bg-white">
      <div class="content-header">
        <form class="w-100" action="/dashboard" method="POST">
          @csrf
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
              <button type="button"
                      class="btn btn-danger"
                      data-toggle="layout"
                      data-action="header_search_off">
                <i class="fa fa-fw fa-times-circle"></i>
              </button>
            </div>
            <input type="text"
                   class="form-control"
                   placeholder="Search or hit ESC.."
                   id="page-header-search-input"
                   name="page-header-search-input">
          </div>
        </form>
      </div>
    </div>
    <!-- END Header Search -->

    <!-- Header Loader -->
    <!-- Please check out the Loaders page under Components category to see examples of showing/hiding it -->
    <div id="page-header-loader" class="overlay-header bg-white">
      <div class="content-header">
        <div class="w-100 text-center">
          <i class="fa fa-fw fa-circle-notch fa-spin"></i>
        </div>
      </div>
    </div>
    <!-- END Header Loader -->
  </header>
</template>

<script>
import UserService from '../../services/user.service'
import VueI18n from '../../i18n'
import { useLive } from '@/views/live/use/useLive'
import GameService from '../../services/game.service'
import { useExternalLinks } from '@/use/useExternalLinks'
import router from '@/router'

export default {
  data () {
    return {
      user: {},
      show: true,
      showMobile: true,
      currentSeason: {}
    }
  },
  setup () {
    const { score, toggleSidebar } = useLive()
    const { getClockLink } = useExternalLinks()

    return {
      score,
      toggleSidebar,
      getClockLink
    }
  },
  computed: {
    config () {
      return this.$store.state.config
    },
    availableLanguages () {
      return this.$store.state.availableLanguages
    },
    currentLanguage () {
      return this.$store.state.language
    },
    isDevelopment () {
      return process.env.NODE_ENV === 'development'
    },
    isMobile: {
      get () {
        return this.$store.state.main.isMobile
      },
      set (value) {
        this.$store.dispatch('main/setIsMobile', value)
      }
    },
    autoUnselectValue () {
      return this.$store.state.main.autoUnselect
    },
    reverseTeamsValue () {
      return this.$store.state.main.reversedTeams
    },
    invertTableValue () {
      return this.$store.state.main.invertedTable
    },
    mobileSettingsValue () {
      return this.$store.state.main.mobileSettings
    },
    actionsPositionValue: {
      get () {
        return this.$store.state.main.actionsPosition
      },
      set (value) {
        this.$store.commit('main/actionsPosition', value)
      }
    },
    model () {
      return this.$store.state.main.model
    },
    currentClient () {
      return this.$store.state.currentClient
    }
  },
  watch: {
    language: 'changeLanguage'
  },
  mounted () {
    // this.getUser()
  },
  methods: {
    goToStopwatch () {
      this.$router.push({ name: 'stopwatch' })
    },
    goBackToScorekeeper () {
      GameService.status().then((response) => {
        if (response.data === 'SCHEDULED') {
          this.$router.push({ name: 'preGame' })
        } else if (response.data === 'LIVE') {
          this.$router.push({ name: 'live' })
        } else if (response.data === 'FINISHED') {
          this.$router.push({ name: 'archive' })
        } else {
          this.logoutOfGame()
        }
      })
    },
    backToLive () {
      GameService.backToLive().then(() => {
        this.$router.push({ name: 'live' })
      })
    },
    setLanguage (language) {
      this.$store.dispatch('setLanguage', language)
    },
    reverseTeams () {
      this.$store.commit('main/reversedTeams', this.reverseTeamsValue)
    },
    invertTable () {
      this.$store.commit('main/invertedTable', this.invertTableValue)
    },
    showMobileSettings () {
      this.$store.commit('main/mobileSettings', this.mobileSettingsValue)
    },
    autoUnselect () {
      this.$store.commit('main/autoUnselect', !this.autoUnselectValue)
    },
    changeLanguage () {
      localStorage.setItem('locale', this.language)
      VueI18n.locale = this.language
    },
    customLabel (o) {
      return `${o.name ? o.name : ''}`
    },
    logoutOfGame () {
      this.currentClient.role !== 'SCOREKEEPER'
        ? router.push({ name: 'games' })
        : router.push({ name: 'enterTheGame' })
    },
    getUser () {
      UserService.getUser().then((response) => {
        this.currentSeason = response.data.currentSeason
        this.$store.commit('main/currentUser', response.data)
      })
    },
    actionsToLeft () {
      this.$store.commit('main/actionsToLeft', this.actionsToLeftValue)
    },
    actionsPosition () {
      this.$store.commit('main/actionsPosition', this.actionsPositionValue)
    }
  }
}
</script>
<style>
.dropdown-settings-item {
  min-width: 250px;
}
.dropdown-view-settings {
  list-style-type: none;
  padding: 10px;
  margin: 0;
}
</style>
