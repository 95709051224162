<template>
  <div>
    <h1 class="text-center display-3">Archive</h1>

    <div class="row">
      <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
        <ntm-block>
          <div class="main-info">
            <div class="row">
              <div class="col-sm-4 text-left"><h3>{{ model.homeTeamName }} </h3></div>
              <div class="col-sm-4 text-center d-flex justify-content-center"><span v-if="model.home_team_score_bonus">({{model.home_team_score_bonus}})</span><h2> {{homeTeamScoreTotal}} : {{awayTeamScoreTotal}}</h2><span v-if="model.away_team_score_bonus">({{model.away_team_score_bonus}})</span></div>
              <div class="col-sm-4 text-right"><h3>{{ model.awayTeamName }} </h3></div>
              <div class="col-6 mb-3" v-if="config.has_score_bonus">
                <label class="form-label">{{$t('formly.fields.bonusPoints')}}</label>
                <input v-model="model.home_team_score_bonus" type="number" class="form-control">
              </div>
              <div class="col-6 mb-3" v-if="config.has_score_bonus">
                <label class="form-label">{{$t('formly.fields.bonusPoints')}}</label>
                <input v-model="model.away_team_score_bonus" type="number" class="form-control">
              </div>
            </div>
          </div>
        </ntm-block>
        <ntm-block>
          <form @submit.prevent="archive()">
            <formly-form :form="form" :model="model" :fields="fields"></formly-form>
            <button class="btn btn-success push-15">{{ $t('archive.save') }}</button>
          </form>
        </ntm-block>
      </div>
    </div>

  </div>
</template>

<script>
import GameService from '../services/game.service'
import router from '@/router'
import store from '@/store'

export default {
  name: 'Archive',
  data () {
    return {
      form: {},
      model: {
        home_team_score_bonus: 0,
        away_team_score_bonus: 0,
        playersOfTheGame: []
      },
      playersOfTheGameOptions: [],
      fields: [],
      selected: '',
      homeErrors: [],
      awayErrors: []
    }
  },
  beforeRouteEnter (to, from, next) {
    GameService.status().then((response) => {
      if (response.data === 'LIVE') {
        router.push({ name: 'Live', params: { clientId: to.params.clientId, code: to.params.code } })
      } else if (response.data === 'SCHEDULED') {
        router.push({ name: 'preGame', params: { clientId: to.params.clientId, code: to.params.code } })
      } else if (response.data === 'FINISHED') {
        GameService.getArchive().then((response) => {
          next(vm => vm.setData(response.data))
        })
      }
    })
  },
  mounted () {
    const fields = [
      {
        key: 'report',
        type: 'html',
        templateOptions: {
          label: 'report',
          assetType: 'game',
          uuid: this.model.uuid
        }
      }
    ]

    if (!this.config.mvp.auto) {
      fields.push({
        key: 'playersOfTheGame',
        type: 'ntm-select',
        multiple: true,
        options: this.playersOfTheGameOptions,
        templateOptions: {
          label: 'playersOfTheGame',
          customLabel: (val) => {
            return `#${val.number_of_jersey} ${val.player.name} (${val.team.name}) - ${val.value}`
          }
        }
      })
      this.getPlayersOfTheGame()
    }

    this.fields = fields
  },
  methods: {
    resultChange () {
      if (!this.config.mvp.auto) {
        this.getPlayersOfTheGame()
      }
    },
    getPlayersOfTheGame () {
      this.model.playersOfTheGame = []
      GameService.getPlayersOfTheGame(this.homeTeamScoreTotal, this.awayTeamScoreTotal).then(res => {
        const field = this.fields.find((field) => field.key === 'playersOfTheGame')
        field.options = res.data
      })
    },
    setData (data) {
      this.model = Object.assign({ ...this.model }, data)
    },
    archive () {
      store.commit('main/loading')
      const data = {
        report: this.model.report,
        home_team_score_bonus: this.model.home_team_score_bonus,
        away_team_score_bonus: this.model.away_team_score_bonus,
        playersOfTheGame: this.model.playersOfTheGame || []
      }
      GameService.archive(data).then(() => {
        localStorage.removeItem('code')
        localStorage.removeItem('homeTeamColor')
        localStorage.removeItem('awayTeamColor')

        this.currentClient.role !== 'SCOREKEEPER'
          ? router.push({ name: 'games' })
          : router.push({ name: 'enterTheGame' })
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
      })
    }
  },
  watch: {
    'model.home_team_score_bonus': 'resultChange',
    'model.away_team_score_bonus': 'resultChange'
  },
  computed: {
    currentClient () {
      return this.$store.state.currentClient
    },
    homeTeamScoreTotal () {
      return Number(this.model.home_team_score_bonus) + Number(this.model.homeTeamScore)
    },
    awayTeamScoreTotal () {
      return Number(this.model.away_team_score_bonus) + Number(this.model.awayTeamScore)
    },
    config () {
      return this.$store.state.config
    }
  }
}

</script>

<style>
  .main-info {}
</style>
