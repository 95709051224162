import { StopwatchService } from '@/services/stopwatch/stopwatch.service'
import GameService from '@/services/game.service'
import { useStore } from '@/store'
import { computed, ref, watch } from 'vue'
import { getPublicChannel } from '@/ably'
import router from '@/router'
import { uuidv4 } from '@/lib/uuid'

const gameUuid = ref(null)
const diffTime = ref(0)
const shotClockDiffTime = ref(0)

export function useStopwatch (type = 'stopwatch') {
  const stopwatchId = ref(uuidv4())
  const activeStopwatch = ref(null)
  const store = useStore()
  const currentClient = computed(() => store.state.currentClient)
  const config = computed(() => store.state.config)
  const isLoaded = ref(false)
  const animateFrame = ref(null)
  const startTime = ref(0)
  const startShotClockTime = ref(0)
  const isEditMode = ref(false)
  const gameChannel = ref(null)
  const stopwatchChannel = ref(null)
  const isReverse = computed(() => store.state.config.time_reverse)
  const isActiveCurrentStopwatch = computed(() => activeStopwatch.value?.clientId === stopwatchId.value)

  const currentPeriod = ref(null)
  const periodTime = ref(null)

  const timeData = ref({
    time: 0,
    shotClock: 0,
    isRunning: false,
    isShotClockUpdated: false
  })

  const isRunning = computed(() => timeData.value.isRunning)
  const currentTime = computed(() => timeData.value.time)
  const currentShotClock = computed(() => timeData.value.shotClock)

  async function updateTime (data) {
    return StopwatchService.setTime(currentClient.value.uuid, gameUuid.value, data)
  }

  async function getGame () {
    const res = await GameService.stopwatch()

    gameUuid.value = res.data.gameUuid
    currentPeriod.value = res.data.currentPeriod
    periodTime.value = res.data.periodTime

    return res.data
  }

  async function getTime () {
    return StopwatchService.getTime(currentClient.value.uuid, gameUuid.value).then((response) => {
      timeData.value = {
        time: response.data.time,
        shotClock: response.data.shotClock,
        isRunning: response.data.isRunning ?? false
      }
    })
  }

  const periodMaxTimeOnPeriod = computed(() => {
    if (isReverse.value) {
      return periodTime.value
    }

    if (currentPeriod.value === 0) {
      return (config.value.periods + 1) * periodTime.value
    }

    return periodTime.value * currentPeriod.value
  })

  const periodMinTimeOnPeriod = computed(() => {
    if (isReverse.value) {
      return 0
    }

    if (currentPeriod.value === 0) {
      return config.value.periods * periodTime.value
    }

    return periodTime.value * (currentPeriod.value - 1)
  })

  const shotClockSeconds = computed(() => {
    return Math.floor(shotClockDiffTime.value / 1000) % 60
  })

  const shotClockMilliSeconds = computed(() => {
    return Math.floor(shotClockDiffTime.value % 1000)
  })

  const formattedShotClockSeconds = computed(() => {
    return zeroPad(shotClockSeconds.value)
  })

  const formattedShotClockMilliSeconds = computed(() => {
    return shotClockMilliSeconds.value.toString().charAt(0)
  })

  const minutes = computed(() => {
    return zeroPad(Math.floor(diffTime.value / 1000 / 60) % 60)
  })

  const seconds = computed(() => {
    return zeroPad(Math.floor(diffTime.value / 1000) % 60)
  })

  const milliSeconds = computed(() => {
    return Math.floor(diffTime.value % 1000)
  })

  const formattedMinutes = computed(() => {
    return zeroPad(minutes.value)
  })

  const formattedSeconds = computed(() => {
    return zeroPad(seconds.value)
  })

  const formattedMilliSeconds = computed(() => {
    return milliSeconds.value.toString().charAt(0)
  })

  const currentTimeToShow = computed(() => {
    return formattedMinutes.value + ':' + formattedSeconds.value + '.' + formattedMilliSeconds.value
  })

  const currentShotClockToShow = computed(() => {
    return formattedShotClockSeconds.value + '.' + formattedShotClockMilliSeconds.value
  })

  function zeroPad (value, num) {
    const newNum = typeof num !== 'undefined' ? num : 2
    return value.toString().padStart(newNum, '0')
  }

  function initTimeByPeriod () {
    if (isActiveCurrentStopwatch.value) {
      diffTime.value = (isReverse.value ? periodMaxTimeOnPeriod.value : periodMinTimeOnPeriod.value)

      stopTimer()
    }
  }

  async function initStopwatch () {
    await getGame()

    gameChannel.value = getPublicChannel(`${currentClient.value.uuid}.game.${gameUuid.value}`)

    await gameChannel.value.subscribe((message) => {
      switch (message.name) {
        case 'start':
          location.reload()
          break
        case 'period':
          currentPeriod.value = message.data.game.current_period
          initTimeByPeriod()
      }
    })

    await getTime()

    shotClockDiffTime.value = timeData.value?.shotClock

    stopwatchChannel.value = getPublicChannel(`${currentClient.value.uuid}.game.${gameUuid.value}.stopwatch`)

    await stopwatchChannel.value.presence.subscribe('enter', (member) => {
      setMember()
    })

    await stopwatchChannel.value.presence.subscribe('leave', (member) => {
      setMember()
    })

    await stopwatchChannel.value.subscribe((message) => {
      switch (message.name) {
        case 'eject':
          if (message.data.clientId === stopwatchId.value) {
            currentClient.value.role !== 'SCOREKEEPER'
              ? router.push({ name: 'games' })
              : router.push({ name: 'enterTheGame' })
          }

          break
      }
    })

    await stopwatchChannel.value.presence.enterClient(stopwatchId.value, { type })

    if (!currentTime.value || (currentTime.value >= periodMaxTimeOnPeriod.value) || (currentTime.value <= periodMinTimeOnPeriod.value)) {
      initTimeByPeriod()
    } else {
      diffTime.value = currentTime.value

      if (isRunning.value) {
        await startTimer(true)
      } else {
        await stopTimer()
      }
    }

    isLoaded.value = true
  }

  async function setMember () {
    const members = await stopwatchChannel.value.presence.get()

    if (members.length === 1) {
      setActiveStopwatch({ clientId: stopwatchId.value, data: { type } })
      await unsubscribeOnStopwatchChannel()
      return
    }

    if (type === 'smallStopwatch') {
      const member = members.find((member) => member.data.type === 'stopwatch') ?? members[0]

      if (member.clientId !== stopwatchId.value) {
        setActiveStopwatch(member)
        await subscribeOnStopwatchChannel()
      }
    } else {
      setActiveStopwatch({ clientId: stopwatchId.value, data: { type } })
      await unsubscribeOnStopwatchChannel()
    }
  }

  function toggleTime () {
    if (!isActiveCurrentStopwatch.value) {
      return
    }

    if (isRunning.value) {
      stopTimer()
    } else {
      startTimer()
    }
  }

  const updateTimeListener = async (message) => {
    switch (message.name) {
      case 'update_time':
        // eslint-disable-next-line no-case-declarations
        const isRunningBefore = isRunning.value

        timeData.value = message?.data

        diffTime.value = message?.data?.time
        shotClockDiffTime.value = message?.data?.shotClock

        if (message?.data?.isRunning === isRunningBefore) {
          return
        }

        if (message?.data?.isRunning) {
          await startTimer()
        } else {
          await stopTimer()
        }

        break
    }
  }
  async function subscribeOnStopwatchChannel () {
    await stopwatchChannel.value.subscribe(updateTimeListener)
  }

  async function unsubscribeOnStopwatchChannel () {
    await stopwatchChannel.value.unsubscribe(updateTimeListener)
  }

  function setActiveStopwatch (member) {
    activeStopwatch.value = member

    isEditMode.value = false
  }

  async function ejectTimer () {
    await stopwatchChannel.value.publish('eject', activeStopwatch.value)
  }

  async function destroyStopwatch () {
    cancelAnimationFrame(animateFrame.value)
    await stopwatchChannel.value.presence.unsubscribe()
    await stopwatchChannel.value.unsubscribe()
    await stopwatchChannel.value.detach()
    await gameChannel.value.unsubscribe()
    await gameChannel.value.detach()
  }

  function toggleEditMode () {
    isEditMode.value = !isEditMode.value
  }

  async function increaseTime (by = 1000) {
    const isRunning = Boolean(timeData.value.isRunning)

    let time

    if (by === 100) {
      time = Math.floor(diffTime.value / by) * by + by
    } else {
      time = diffTime.value + by
    }

    if (time > periodMaxTimeOnPeriod.value) {
      time = periodMaxTimeOnPeriod.value
    }

    if (diffTime.value === time) {
      return
    }

    if (isRunning) {
      stopTimer()
    }

    diffTime.value = time

    if (isRunning) {
      startTimer()
    }

    await updateStopwatchTime()
  }

  async function decreaseTime (by = 1000) {
    const isRunning = Boolean(timeData.value.isRunning)

    let time

    if (by === 100) {
      time = Math.floor(diffTime.value / by) * by - by
    } else {
      time = diffTime.value - by
    }

    if (time < periodMinTimeOnPeriod.value) {
      time = periodMinTimeOnPeriod.value
    }

    if (diffTime.value === time) {
      return
    }

    if (isRunning) {
      stopTimer()
    }

    diffTime.value = time

    if (isRunning) {
      startTimer()
    }

    await updateStopwatchTime()
  }

  async function increaseShotClockTime (by = 1000) {
    const isRunning = Boolean(timeData.value.isRunning)

    if (isRunning) {
      return
    }

    let time

    if (by === 100) {
      time = Math.floor(timeData.value.shotClock / by) * by + by
    } else {
      time = timeData.value.shotClock + by
    }

    if (time > 24000) {
      time = 24000
    }

    if (timeData.value.shotClock === time) {
      return
    }

    timeData.value.shotClock = time

    await updateStopwatchTime()
  }

  async function decreaseShotClockTime (by = 1000) {
    const isRunning = Boolean(timeData.value.isRunning)

    if (isRunning) {
      return
    }

    let time

    if (by === 100) {
      time = Math.floor(timeData.value.shotClock / by) * by - by
    } else {
      time = timeData.value.shotClock - by
    }

    if (time < 0) {
      time = 0
    }

    if (timeData.value.shotClock === time) {
      return
    }

    timeData.value.shotClock = time

    await updateStopwatchTime()
  }

  function updateStopwatchTimeEverySecond () {
    if (isRunning.value && isActiveCurrentStopwatch.value) {
      const data = { time: diffTime.value, shotClock: shotClockDiffTime.value, isRunning: isRunning.value, isShotClockUpdated: false }

      updateTime(data)
    }
  }

  function playBuzzer () {
    stopwatchChannel.value.publish('buzzer', null)
  }

  async function updateStopwatchTime (isShotClockUpdated = false) {
    if (!isActiveCurrentStopwatch.value) {
      return
    }

    const data = {
      isRunning: timeData.value.isRunning,
      time: diffTime.value,
      shotClock: shotClockDiffTime.value ?? 0,
      isShotClockUpdated
    }

    await stopwatchChannel.value.publish('update_time', data)

    updateTime(data)
  }

  function setSubtractStartTime (time) {
    const newTime = typeof time !== 'undefined' ? time : 0
    startTime.value = Math.floor((isReverse.value ? -performance.now() : performance.now()) - newTime)
  }

  function setSubtractShotClockStartTime (time) {
    const newTime = typeof time !== 'undefined' ? time : 0
    startShotClockTime.value = Math.floor(-performance.now() - newTime)
  }

  async function resetShotClock (seconds) {
    shotClockDiffTime.value = seconds * 1000
    setSubtractShotClockStartTime(shotClockDiffTime.value)
    await updateStopwatchTime(true)
  }

  async function startTimer (force = false) {
    if (isRunning.value && isActiveCurrentStopwatch.value && !force) {
      return
    }

    timeData.value.isRunning = true

    await updateStopwatchTime()
    setSubtractStartTime(diffTime.value)
    setSubtractShotClockStartTime(shotClockDiffTime.value)
    loop()
  }

  function loop () {
    if (isReverse.value && (diffTime.value <= 100)) {
      diffTime.value = 0
      stopTimer()
      return
    } else if (!isReverse.value && (diffTime.value >= periodMaxTimeOnPeriod.value)) {
      diffTime.value = periodMaxTimeOnPeriod.value
      stopTimer()
      return
    }

    if (shotClockDiffTime.value < 100) {
      shotClockDiffTime.value = 0
    } else {
      shotClockDiffTime.value = -performance.now() - startShotClockTime.value
    }

    const nowTime = Math.floor(performance.now())
    diffTime.value = (isReverse.value ? -nowTime : nowTime) - startTime.value

    animateFrame.value = requestAnimationFrame(loop)
  }

  async function stopTimer () {
    timeData.value.isRunning = false

    if (animateFrame.value) {
      cancelAnimationFrame(animateFrame.value)
    }

    await updateStopwatchTime()
  }

  watch(seconds, () => {
    updateStopwatchTimeEverySecond()
  }, { immediate: true })

  return {
    periodMaxTimeOnPeriod,
    periodMinTimeOnPeriod,
    currentTimeToShow,
    currentShotClockToShow,
    formattedMinutes,
    formattedSeconds,
    formattedMilliSeconds,
    formattedShotClockSeconds,
    formattedShotClockMilliSeconds,
    minutes,
    seconds,
    milliSeconds,
    shotClockSeconds,
    shotClockMilliSeconds,
    isReverse,
    currentClient,
    config,
    gameUuid,
    currentPeriod,
    periodTime,
    timeData,
    currentTime,
    currentShotClock,
    updateTime,
    getTime,
    initStopwatch,
    destroyStopwatch,
    toggleEditMode,
    increaseTime,
    decreaseTime,
    increaseShotClockTime,
    decreaseShotClockTime,
    updateStopwatchTimeEverySecond,
    playBuzzer,
    resetShotClock,
    startTimer,
    loop,
    stopTimer,
    isEditMode,
    isRunning,
    isLoaded,
    activeStopwatch,
    toggleTime,
    ejectTimer,
    isActiveCurrentStopwatch,
    diffTime,
    shotClockDiffTime
  }
}
