<template>
  <div class="home">
    <page-content>
      <div class="row">
        <div class="offset-md-2 col-md-8">
          <ntm-block>
            <table v-if="model.games.length" class="table table-bordered table-striped table-vcenter">
              <thead>
              <tr>
                <th></th>
                <th>Time</th>
                <th>Game</th>
                <th>League</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="game in model.games" :key="game.uuid" :class="gameStatusColor(game.status)">
                <td>
                    <div>
                      <button class="btn btn-info btn-sm mr-2"
                              @click="enterTheGame(game)" :disabled="!game.teamA && !game.teamB"><i class="fa fa fa-sign-in"></i></button> <span>{{game.code}}</span>
                    </div>
                </td>
                <td>{{ $d(new Date(game.time.replace('T', ' ').replace(/-/g, '/')), 'dayWithTime') }}</td>
                <td><strong>{{ game.teamA?.name ?? '?' }}</strong> :
                  <strong>{{ game.teamB?.name ?? '?' }}</strong>
                </td>
                <td>{{ game.schedule.name }}</td>
              </tr>
              </tbody>
            </table>
            <div v-else class="text-center">
              <p>There is no scheduled games found</p>
            </div>
          </ntm-block>
        </div>
      </div>

    </page-content>
  </div>
</template>
<script>
import GameService from '@/services/game.service'
import ApiService from '@/services/api.service'
import { gameStatusColor } from '@/lib/status'
import { redirectToGameRouteByStatus } from '@/use/useRedirectGame'

export default {
  data () {
    return {
      model: {
        games: []
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    GameService.index().then((response) => {
      next(vm => vm.setData(response.data))
    }).catch(() => {
      next({ name: 'enterTheGame' })
    })
  },
  methods: {
    enterTheGame (game) {
      ApiService.setGameCode(game.code)
      GameService.status().then((res) => {
        this.$router.push(redirectToGameRouteByStatus(this.$route.params.clientId, game.code, res.data))
      }).catch((error) => {
        this.errorCode = error.response.status
      })
    },
    gameStatusColor,
    setData (data) {
      this.model = data
    }
  }
}
</script>
