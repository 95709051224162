export function redirectToGameRouteByStatus (clientId, code, status) {
  switch (status) {
    case 'SCHEDULED':
      return { name: 'preGame', params: { clientId, code: code } }
    case 'LIVE':
      return { name: 'live', params: { clientId, code: code } }
    case 'FINISHED':
      return { name: 'archive', params: { clientId, code: code } }
    default:
      throw new Error('Invalid game status')
  }
}
