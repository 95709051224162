<template>
  <div
    class="stopwatch-container"
  >
    <stopwatch/>
  </div>
</template>
<script>
import Stopwatch from '@/components/stopwatch/Stopwatch.vue'

export default {
  name: 'StopwatchView',
  components: {
    Stopwatch
  }
}
</script>

<style lang="scss" scoped>
.stopwatch-container {
  @media (max-width: 1024px) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 16px;
  }
}
</style>
