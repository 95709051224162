import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Default from '../layouts/Default'
import Auth from '../layouts/Auth'
import Resolve from '@/views/Resolve.vue'
import Login from '@/views/auth/Login.vue'
import RegisterFromInvite from '@/views/auth/RegisterFromInvite.vue'
import { TokenService } from '@/services/storage.service'
import EnterTheGame from '@/views/EnterTheGame.vue'
import PreGame from '@/views/PreGame.vue'
import Live from '@/views/live/Live.vue'
import Archive from '@/views/Archive.vue'
import ScorekeeperLayout from '@/layouts/ScorekeeperLayout.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import Games from '@/views/Games.vue'
import StopwatchView from '@/views/StopwatchView.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/auth',
      name: 'auth',
      component: Auth,
      children: [
        {
          path: 'login',
          name: 'login',
          component: Login,
          meta: {
            public: true,
            onlyWhenLoggedOut: true
          }
        },
        {
          path: 'register-from-invite/:token',
          name: 'register-from-invite',
          component: RegisterFromInvite,
          meta: {
            public: true,
            onlyWhenLoggedOut: false
          }
        }
      ]
    },
    {
      path: '',
      name: 'mainLayout',
      component: MainLayout,
      children: [
        {
          path: '',
          name: 'panel',
          component: Default,
          beforeRouteUpdate: () => {
            store.commit('loaded')
          },
          children: [
            {
              path: '/',
              name: 'resolve',
              component: Resolve
            },
            {
              path: ':clientId/enter-the-game',
              name: 'enterTheGame',
              component: EnterTheGame
            },
            {
              path: ':clientId/games',
              name: 'games',
              component: Games
            }
          ]
        },
        {
          path: '',
          name: 'scorekeeper',
          component: ScorekeeperLayout,
          beforeRouteUpdate: () => {
            store.commit('loaded')
          },
          children: [
            {
              path: ':clientId/games/:code/pre-game',
              name: 'preGame',
              component: PreGame
            },
            {
              path: ':clientId/games/:code/live',
              name: 'live',
              component: Live
            },
            {
              path: ':clientId/games/:code/stopwatch',
              name: 'stopwatch',
              component: StopwatchView
            },
            {
              path: ':clientId/games/:code/archive',
              name: 'archive',
              component: Archive
            }
          ]
        }
      ]
    }

  ],
  scrollBehavior () {
    const layoutId = document.getElementById('page-container')

    if (layoutId) {
      layoutId.scrollTo(0, 0)
    }
  }
})

router.beforeEach(async (to, from, next) => {
  // await store.dispatch('setClient', value)
  // eslint-disable-next-line
  jQuery('.modal').modal('hide')
  store.commit('loading')
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = !!TokenService.getToken()
  if (!isPublic && !loggedIn) {
    return next({
      name: 'login',
      query: { redirect: to.fullPath }
      // Store the full path to redirect the user to after login
    })
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }

  next()
})

router.afterEach(() => {
  // eslint-disable-next-line
  store.dispatch('closeNav')
  store.commit('loaded')
})

export default router
