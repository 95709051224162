import * as Ably from 'ably'

const options = { key: process.env.VUE_APP_ABLY_KEY }
const ably = new Ably.Realtime(options)

window.ably = ably
function getPublicChannel (name) {
  return ably.channels.get('public:' + name)
}

async function unsubscribePublicChannel (name) {
  const channel = getPublicChannel(name)
  await channel.unsubscribe()
  await channel.detach()
}
export { ably, getPublicChannel, unsubscribePublicChannel }
