<template>
  <div>
    <Loader v-if="!isLoaded"></Loader>
    <div  v-if="isLoaded" class="stopwatch-wrapper">
      <div class="buzzer">
        <button
          class="buzzer--button"
          style="flex-grow: 1;"
          @click="playBuzzer()"
        >
          <buzzer-icon class="left" />
          <span>Buzzer</span>
          <buzzer-icon />
        </button>
        <button
          :class="{'editing': isEditMode}"
          class="buzzer--edit"
          @click="toggleEditMode()"
        >
          <edit-icon class="text-white" style="height: 25px" />
        </button>
      </div>

      <div class="offense-time">
        <div
          v-if="isEditMode"
          class="edit-offense-time"
        >
          <button
            :class="{'editing-button': isEditMode}"
            @click="increaseShotClockTime()"
          >
            <arrow-up-icon class="h-6" />
          </button>
          <button
            :class="{'editing-button': isEditMode}"
            @click="decreaseShotClockTime()"
          >
            <arrow-down-icon class="h-6" />
          </button>
        </div>
        <p class="time">
          {{ currentShotClockToShow }}
        </p>
        <div
          v-if="isEditMode"
          class="edit-offense-time"
        >
          <button
            :class="{'editing-button': isEditMode}"
            @click="increaseShotClockTime(100)"
          >
            <arrow-up-icon class="h-6" />
          </button>
          <button
            :class="{'editing-button': isEditMode}"
            @click="decreaseShotClockTime(100)"
          >
            <arrow-down-icon class="h-6" />
          </button>
        </div>
      </div>
      <div class="offense-type">
        <button
          v-for="(shotClock, i) in config.timer.shotClocks"
          :key="i"
          :class="shotClock === 14 ? 'small-offense' : 'big-offense'"
          @click="resetShotClock(shotClock)"
        >
          <p class="title">
            {{ shotClock }}
          </p>
          <div v-if="!isEditMode" class="numpad-wrap">
            <span class="num">{{ i + 1 }}</span>
          </div>
        </button>
      </div>
      <div class="time-trigger">
        <div v-if="isEditMode" class="edit-time up">
          <button
            :class="{'editing-button': isEditMode}"
            @click="increaseTime(60000)"
          >
            <arrow-up-icon class="h-6" />
          </button>
          <button
            :class="{'editing-button': isEditMode}"
            @click="increaseTime()"
          >
            <arrow-up-icon class="h-6" />
          </button>
          <button
            :class="{'editing-button': isEditMode}"
            @click="increaseTime(100)"
          >
            <arrow-up-icon class="h-6" />
          </button>
        </div>
        <div v-if="isEditMode" class="edit-time down">
          <button
            :class="{'editing-button': isEditMode}"
            @click="decreaseTime(60000)"
          >
            <arrow-down-icon class="h-6" />
          </button>
          <button
            :class="{'editing-button': isEditMode}"
            @click="decreaseTime()"
          >
            <arrow-down-icon class="h-6" />
          </button>
          <button
            :class="{'editing-button': isEditMode}"
            @click="decreaseTime(100)"
          >
            <arrow-down-icon class="h-6" />
          </button>
        </div>
        <button
          v-if="!isRunning"
          type="button"
          class="trigger-button"
          @click="startTimer"
        >
          <p>{{ currentTimeToShow }}</p>
          <div
            v-if="!isEditMode"
            class="numpad-wrap"
          >
            <span class="num">Space</span>
            <span>or</span>
            <span class="num">0</span>
          </div>
        </button>
        <button
          v-if="isRunning"
          type="button"
          class="trigger-button"
          @click="stopTimer"
        >
          <p>{{ currentTimeToShow }}</p>
          <div
            v-if="!isEditMode"
            class="numpad-wrap"
          >
            <span class="num">Space</span>
            <span>or</span>
            <span class="num">0</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ArrowUpIcon from '@/components/stopwatch/icons/ArrowUpIcon.vue'
import ArrowDownIcon from '@/components/stopwatch/icons/ArrowDownIcon.vue'
import EditIcon from '@/components/stopwatch/icons/EditIcon.vue'
import { useStopwatch } from '@/use/useStopwatch'
import BuzzerIcon from '@/components/stopwatch/icons/BuzzerIcon.vue'
import { onMounted, onUnmounted } from 'vue'
import Loader from '@/components/layout/Loader.vue'

export default {
  name: 'LeStopwatch',
  components: {
    Loader,
    BuzzerIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    EditIcon
  },
  setup () {
    const {
      config,
      gameUuid,
      currentClient,
      updateStopwatchTimeEverySecond,
      decreaseShotClockTime,
      increaseShotClockTime,
      currentShotClockToShow,
      currentTimeToShow,
      currentTime,
      resetShotClock,
      toggleEditMode,
      increaseTime,
      decreaseTime,
      startTimer,
      stopTimer,
      initStopwatch,
      destroyStopwatch,
      isEditMode,
      isRunning,
      playBuzzer,
      isLoaded
    } = useStopwatch()

    function keyboardListener (event) {
      if (['Space', 'Numpad0', 'Digit0'].includes(event.code)) {
        event.preventDefault()
        isRunning.value ? stopTimer() : startTimer()
        return
      }

      if (!isNaN(event.key)) {
        event.preventDefault()
        resetShotClock(config.value.timer.shotClocks[parseInt(event.key) - 1])
      }
    }

    onMounted(() => {
      initStopwatch()
      window.addEventListener('keydown', keyboardListener)
    })

    onUnmounted(async () => {
      await destroyStopwatch()
      window.removeEventListener('keydown', keyboardListener)
    })

    return {
      config,
      gameUuid,
      currentClient,
      updateStopwatchTimeEverySecond,
      decreaseShotClockTime,
      increaseShotClockTime,
      currentShotClockToShow,
      currentTimeToShow,
      resetShotClock,
      toggleEditMode,
      increaseTime,
      decreaseTime,
      startTimer,
      stopTimer,
      initStopwatch,
      destroyStopwatch,
      isEditMode,
      isRunning,
      playBuzzer,
      currentTime,
      isLoaded
    }
  }
}
</script>

<style lang="scss" scoped>
.stopwatch-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 890px;
  margin: 20px auto 0;
  .buzzer {
    display: flex;
    flex-grow: 1;
    gap: 16px;
    &--button {
      width: 100%;
      height: 48px;
      background: linear-gradient(45deg, oklch(0.577 0.245 27.325), oklch(0.444 0.177 26.899));
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
      border: 1px solid oklch(0.444 0.177 26.899);
      box-shadow: inset 0 0 0 1px oklch(0.577 0.245 27.325);
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      svg {
        height: 28px;
      }

      .left {
        transform: scale(-1, 1);
      }
    }
    &--edit {
      height: 48px;
      width: 48px;
      background: linear-gradient(45deg, oklch(0.623 0.214 259.815), oklch(0.488 0.243 264.376));
      border: 1px solid oklch(0.488 0.243 264.376);
      box-shadow: inset 0 0 0 1px oklch(0.623 0.214 259.815);
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .offense-time {
    position: relative;
    width: 100%;
    flex-grow: 1;
    background: linear-gradient(45deg, oklch(0.585 0.233 277.117), oklch(0.457 0.24 277.023));
    border: 1px solid oklch(0.457 0.24 277.023);
    box-shadow: inset 0 0 0 1px oklch(0.585 0.233 277.117);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    gap: 16px;
    .time {
      font-size: 60px;
      font-weight: 600;
      text-align: center;
      margin: 0;
      color: white;
    }
    .edit-offense-time {
      display: flex;
      flex-direction: column;
      gap: 4px;

      button {
        width: 24px;
        height: 24px;
        flex: none;
        background-color: oklch(0.87 0.065 274.039);
        border-radius: 6px;
        border: 0;
        padding: 0;
        color: oklch(0.398 0.195 277.366);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          height: 14px;
        }
      }
    }
  }
  .offense-type {
    width: 100%;
    display: flex;
    gap: 16px;
    .title {
      font-size: 60px;
      color: white;
      font-weight: 600;
      margin: 0;
    }
    .big-offense,
    .small-offense {
      flex-grow: 1;
      position: relative;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .big-offense {
      background: linear-gradient(45deg, oklch(0.704 0.14 182.503), oklch(0.52 0.105 223.128));
      border: 1px solid oklch(0.52 0.105 223.128);
      box-shadow: inset 0 0 0 1px oklch(0.704 0.14 182.503);
      .num {
        background-color: oklch(0.953 0.051 180.801);
        color: oklch(0.437 0.078 188.216);
      }
    }
    .small-offense {
      background: linear-gradient(45deg, oklch(0.685 0.169 237.323), oklch(0.5 0.134 242.749));
      border: 1px solid oklch(0.5 0.134 242.749);
      box-shadow: inset 0 0 0 1px oklch(0.685 0.169 237.323);

      .num {
        background-color: oklch(0.901 0.058 230.902);
        color: oklch(0.391 0.09 240.876);
      }
    }
    .numpad-wrap {
      position: absolute;
      top: 6px;
      left: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      .num {
        padding: 4px 8px;
        border-radius: 6px;
      }
    }
  }
  .time-trigger {
    width: 100%;
    position: relative;
    .trigger-button {
      width: 100%;
      height: 160px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background: linear-gradient(45deg, oklch(0.828 0.189 84.429), oklch(0.666 0.179 58.318));
      border: 1px solid oklch(0.666 0.179 58.318);
      box-shadow: inset 0 0 0 1px oklch(0.828 0.189 84.429);
      p {
        font-size: 60px;
        font-weight: 600;
        margin: 0;
        line-height: 1;
        color: white;
      }
      .numpad-wrap {
        position: absolute;
        top: 6px;
        left: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: oklch(0.414 0.112 45.904);
        gap: 4px;
        .num {
          padding: 4px 8px;
          border-radius: 6px;
          background-color: oklch(0.962 0.059 95.617);
        }
      }
    }
    .edit-time {
      display: flex;
      gap: 4px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
      &.up {
        top: 20px;
      }
      &.down {
        bottom: 20px;
      }
      button {
        width: 60px;
        height: 24px;
        flex: none;
        background-color: oklch(0.962 0.059 95.617);
        border-radius: 6px;
        border: 0;
        padding: 0;
        color: oklch(0.414 0.112 45.904);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          height: 14px;
        }
      }
    }
  }
}

.up-action,
.down-action,
.up-action svg,
.down-action svg {
    transition: 0.1s;
}

.up-action:active,
.down-action:active {
  background-color: rgba(255, 255, 255, 0.4);
}

.up-action:active svg {
  transform: translateY(-5px);
}

.down-action:active svg {
  transform: translateY(5px);
}

button {
  position: relative;
  transition: 0.1s;
}

button:active {
  opacity: 0.8;
}
button:focus {
  outline: 0;
  box-shadow: none;
}

.editing svg {
  animation: edit 1s linear infinite;
}
@keyframes edit {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
</style>
